<template>
  <div class="tabs_wrap">
    <div class="item" v-for="item,index in loginTypes" :key="index" :class="value === item.value ? 'active' : ''" :style="(theme == 'light' && value == item.value) ? {color: 'var(--btnsizeColor)'}:{}"
      @click="$emit('update',item.value)">
      {{$t('loginType.'+item.title)}}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      default: "phone"
    }
  },
  computed: {
    ...mapGetters(["theme"]),
  },
  model: {
    prop: "value",
    event: "update"
  },
  created() {
    this.$emit('update', this.loginTypes[0].value)
  },
  data() {
    this.loginTypes = [{ title: "phone", value: "phone" }, { title: "email", value: "email" }]
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.tabs_wrap {
  display: flex;
  // border: 1px solid var(--theme);
  margin-bottom: 12px;
  border-radius: 4px;
  justify-content: space-between;

  >.item {
    // width: 50%;
    width: 45%;
    @height: 40px;
    line-height: @height;
    text-align: center;
    // color: var(--nav-background);
    color: var(--sizeColor);
    border-radius: @height / 2;
    border: 1px solid var(--theme);
  }

  >.active {
    // background: var(--nav-background);
    background: var(--btnColor);
    border: 1px solid var(--btnColor);
    // color: #fff;
    color: var(--newbtnsizeColor);
  }
}
</style>